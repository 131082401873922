.mySwiper1 {
    width: 100%;
    height: 100%;
  }
  
  .partnerSlider {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .eventText {
    display: block;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
}


  .partnerSlider img {
    display: block;
    object-fit: cover;
  }



@media screen and (max-width: 768px) {
  .eventText {
    font-size: 10px;
}
}
  




/* h1 {
  padding: 10px 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

h1 span {
  opacity: 0;
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  display: inline-block;
  transform: translateX(-25px);
}

h1:hover span {
  opacity: 1;
  transform: translateX(5px);
}

.marquee-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.partnerSlider {
  display: inline-block;
  margin-right: 20px;
}
.partnerSlider img {
height: 150px;
width: auto;
}
.partnerSlider p {
font-size: large;
text-align: center;
font-weight: bold;
text-transform: uppercase;

}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
} */

