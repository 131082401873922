.ModelImage{
    object-fit: cover;
    height:500px
}

.ant-modal-root .ant-modal-wrap{
    overflow: hidden;
}

.profile{
    display: flex;
    flex-direction: row;
    gap:5rem;
}
.left{
  width:20% ;
  border-right: rgba(255, 255, 255, 0.192) 1px solid;
  padding-right: 20px;
}

.right{
  width:70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-up{
  display: flex;
  flex-direction: row;
  gap:20px;
}
.profile-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom:5rem;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  
  .profile-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-details {
    flex: 1;
  }
  
  .name {
    font-size: 20px;
    font-weight: bold;
  }
  .strikeOut{
    display: inline;
    text-decoration: line-through;
    -webkit-text-fill-color: rgba(168, 32, 32, 0.708);
  }

  .email, .bio {
    margin: 5px 0;
  }
  .edit-Pic{
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #c71b29;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .edit-button, .save-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #c71b29;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button:hover, .save-button:hover {
    background-color: #c71b29;
  }
  
  .edit-form .form-group {
    margin-bottom: 15px;
  }
  
  .edit-form .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .edit-form .form-group input, .edit-form .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .gallery-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .TwoField{
    display: flex;
    flex-direction: row;
    gap: 10px;

  }
  .upload-input {
    margin-bottom: 20px;
  }
  .profile label{
    text-align: left;
  }
  .gallery img{
    position: relative;
    height: 200px;
    width: auto;
  }
  .gallery1 img{
    position: relative;
    height: 250px;
    width: auto;
  }
  .gallery1{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
  }
  .gallery-item {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .gallery-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  /* edit modal css */

.movieTrailers-container1 {
  height: 350px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.9)
}

/* Modal container */
.modal {
  display: block; /* Ensure the modal is displayed */
  position: fixed; /* Fixed position to overlay on top of other content */
  z-index: 1000; /* Ensure it's on top of everything else */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  overflow: hidden; /* Enable scrolling if content exceeds viewport */
}

/* Modal content */
.modal-content1 {
  background-color: #000;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  overflow: hidden !important;
  max-width: 600px; /* Maximum width of the modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for a lifted effect */
}
.modal-fields{
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-upload{
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px 0;
}
.profile-preview {
  width: auto;
  height: 200px;
  margin-bottom: 15px;
  margin-right: 15px;
}
/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #c71b29;
  text-decoration: none;
  cursor: pointer;
}

/* Profile preview */


/* File input */
.input-div input[type='file'] {
  display: none;
}

/* Save button */
.save-button {
  background-color: #c71b29;
  color: white;
  padding: 10px 20px;
  border: none;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
}

.save-button:hover {
  background-color: #c71b29;
}

.two-input{
  display: flex;
  flex-direction: row ;
  gap: 10px;
}


.input-div {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #c71b29;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 0px 100px #c71b29, inset 0px 0px 10px #c71b29, 0px 0px 5px rgb(255, 255, 255);
  animation: flicker 2s linear infinite;
}

.icon {
  color: #c71b29;
  font-size: 2rem;
  cursor: pointer;
  animation: iconflicker 2s linear infinite;
}

/* .input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
} */

@keyframes flicker {
  0% {
    border: 2px solid #c71b29;
    box-shadow: 0px 0px 100px #c71b29, inset 0px 0px 10px #c71b29, 0px 0px 5px rgb(255, 255, 255);
  }

  5% {
    border: none;
    box-shadow: none;
  }

  10% {
    border: 2px solid #c71b29;
    box-shadow: 0px 0px 100px #c71b29, inset 0px 0px 10px #c71b29, 0px 0px 5px rgb(255, 255, 255);
  }

  25% {
    border: none;
    box-shadow: none;
  }

  30% {
    border: 2px solid #c71b29;
    box-shadow: 0px 0px 100px #c71b29, inset 0px 0px 10px #c71b29, 0px 0px 5px rgb(255, 255, 255);
  }

  100% {
    border: 2px solid #c71b29;
    box-shadow: 0px 0px 100px #c71b29, inset 0px 0px 10px #c71b29, 0px 0px 5px rgb(255, 255, 255);
  }
}


@keyframes iconflicker {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0.2;
  }

  10% {
    opacity: 1;
  }

  25% {
    opacity: 0.2;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


/* loader */

.hourglassBackground {
  position: relative;
  background-color: rgb(71, 60, 60);
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin: 30px auto;
}

.hourglassContainer {
  position: absolute;
  top: 30px;
  left: 40px;
  width: 50px;
  height: 70px;
  -webkit-animation: hourglassRotate 2s ease-in 0s infinite;
  animation: hourglassRotate 2s ease-in 0s infinite;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.hourglassContainer div,
.hourglassContainer div:before,
.hourglassContainer div:after {
  transform-style: preserve-3d;
}

@-webkit-keyframes hourglassRotate {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

@keyframes hourglassRotate {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.hourglassCapTop {
  top: 0;
}

.hourglassCapTop:before {
  top: -25px;
}

.hourglassCapTop:after {
  top: -20px;
}

.hourglassCapBottom {
  bottom: 0;
}

.hourglassCapBottom:before {
  bottom: -25px;
}

.hourglassCapBottom:after {
  bottom: -20px;
}

.hourglassGlassTop {
  transform: rotateX(90deg);
  position: absolute;
  top: -16px;
  left: 3px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #999999;
}

.hourglassGlass {
  perspective: 100px;
  position: absolute;
  top: 32px;
  left: 20px;
  width: 10px;
  height: 6px;
  background-color: #999999;
  opacity: 0.5;
}

.hourglassGlass:before,
.hourglassGlass:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #999999;
  left: -17px;
  width: 44px;
  height: 28px;
}

.hourglassGlass:before {
  top: -27px;
  border-radius: 0 0 25px 25px;
}

.hourglassGlass:after {
  bottom: -27px;
  border-radius: 25px 25px 0 0;
}

.hourglassCurves:before,
.hourglassCurves:after {
  content: '';
  display: block;
  position: absolute;
  top: 32px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #333;
  animation: hideCurves 2s ease-in 0s infinite;
}

.hourglassCurves:before {
  left: 15px;
}

.hourglassCurves:after {
  left: 29px;
}

@-webkit-keyframes hideCurves {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideCurves {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.hourglassSandStream:before {
  content: '';
  display: block;
  position: absolute;
  left: 24px;
  width: 3px;
  background-color: white;
  -webkit-animation: sandStream1 2s ease-in 0s infinite;
  animation: sandStream1 2s ease-in 0s infinite;
}

.hourglassSandStream:after {
  content: '';
  display: block;
  position: absolute;
  top: 36px;
  left: 19px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  animation: sandStream2 2s ease-in 0s infinite;
}

@-webkit-keyframes sandStream1 {
  0% {
    height: 0;
    top: 35px;
  }

  50% {
    height: 0;
    top: 45px;
  }

  60% {
    height: 35px;
    top: 8px;
  }

  85% {
    height: 35px;
    top: 8px;
  }

  100% {
    height: 0;
    top: 8px;
  }
}

@keyframes sandStream1 {
  0% {
    height: 0;
    top: 35px;
  }

  50% {
    height: 0;
    top: 45px;
  }

  60% {
    height: 35px;
    top: 8px;
  }

  85% {
    height: 35px;
    top: 8px;
  }

  100% {
    height: 0;
    top: 8px;
  }
}

@-webkit-keyframes sandStream2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  91% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sandStream2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  91% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.hourglassSand:before,
.hourglassSand:after {
  content: '';
  display: block;
  position: absolute;
  left: 6px;
  background-color: white;
  perspective: 500px;
}

.hourglassSand:before {
  top: 8px;
  width: 39px;
  border-radius: 3px 3px 30px 30px;
  animation: sandFillup 2s ease-in 0s infinite;
}

.hourglassSand:after {
  border-radius: 30px 30px 3px 3px;
  animation: sandDeplete 2s ease-in 0s infinite;
}

@-webkit-keyframes sandFillup {
  0% {
    opacity: 0;
    height: 0;
  }

  60% {
    opacity: 1;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 17px;
  }
}

@keyframes sandFillup {
  0% {
    opacity: 0;
    height: 0;
  }

  60% {
    opacity: 1;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 17px;
  }
}

@-webkit-keyframes sandDeplete {
  0% {
    opacity: 0;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  1% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  24% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  25% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  50% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  90% {
    opacity: 1;
    top: 41px;
    height: 0;
    width: 10px;
    left: 20px;
  }
}

@keyframes sandDeplete {
  0% {
    opacity: 0;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  1% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  24% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  25% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  50% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  90% {
    opacity: 1;
    top: 41px;
    height: 0;
    width: 10px;
    left: 20px;
  }
}



  @media (max-width: 768px){
    .profile-info{
      align-items: center;
      text-align: center;
    }
    .ModelImage{
      height:400px;
  }
    .profile{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
  }
  .profile-pic{
    margin-right: 0;
  }
.right-up{
  display: flex;
      flex-direction: column;
}
.gallery1{
  display: flex;
 flex-direction: column;
  gap: 10px;
}
.movieTrailers-container1 {
  height: 250px;
}
.gallery1 img{
  height:auto ;
}
.profile-info{
  margin-top: 0;
}
.left{
  width: 100%;
  border: none;
  padding-right: 0px;
} 
.right{
  width: 100%;
}
.title{
  text-align: center;
}
  }