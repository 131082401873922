.movie-container {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(60deg, #ff0015, #c71b29);
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.movie-link:hover .overlay {
    height: auto;
    width: 100%;
}

.movie-name {
    color:#ffffff;
    font-size: 16px;
    text-align: center;
    padding: 8px;
    margin: 0;
    text-transform: uppercase;
}
    .ReactModalPortal .ReactModal__Overlay {
        background: rgba(0, 0, 0, 0.844) !important;
        z-index:9999999999;
    }
   .ReactModal__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    border: none!important;
    gap: 20px;
    margin-top: 50px;
    padding: 0px !important;
  }
    .Esc{
        text-transform: uppercase;
    }
@media screen and (max-width: 512px) {
    .movie-name {
      font-size: 10px;
      padding: 4px;
  }
  .Esc{
    display: none;
    color:rgba(0, 0, 0, 0.383) !important;
    }
    .ReactModal__Content {
        padding: 0px !important;
      }
  }